<template>
  <div>
    <div class="between-center mb-m">
      <div class="d-flex gap-xxs">
        <VButton
          size="menu"
          icon="filter"
          color="primary-sm"
          :active="isFilter"
          @click.native="toggleFilter"
        />

        <VInput
          v-model="searchValue"
          type="search"
          placeholder="Поиск по номеру заказа и артикулу"
          width="460px"
          @onEnter="setSearchAndFetch"
        />

        <ButtonRefresh
          :disabled="isLoading"
          @click.native="fetchReclamations"
        />
      </div>

      <VButton
        v-if="$store.getters['permission/permissionCreateReclamation']"
        text="Создать рекламацию"
        icon="create"
        size="sm"
        color="primary-sm"
        @click.native="goToReclamationCreatePage"
      />
    </div>

    <ReclamationExternalFilterList
      v-if="isFilter"
      class="mb-m"
      @request="loadPage(1)"
      @reset="loadPage(1)"
    />

    <NavTabsSwiper
      :tabs-list="tabs"
      class-name-nav-item=""
      :is-loading="isLoading"
    />

    <Spinner v-if="isLoading" />

    <div v-else class="column gap-s">
      <ReclamationExternalCard
        v-for="reclamation of reclamations"
        :key="reclamation.id"
        :reclamation="reclamation"
      />
    </div>

    <AppPagination
      v-if="pagination.pageCount > 1"
      :current-page="pagination.page"
      :page-count="pagination.pageCount"
      @setPage="loadPage"
    />
  </div>
</template>

<script>
import {
  ReclamationExternalCard,
  reclamationExternalModel
} from '@/entities/reclamation-external'
import VButton from '@/components/simple/button/VButton.vue'
import ButtonRefresh from '@/components/simple/button/ButtonRefresh.vue'
import VInput from '@/components/Form/Vinput/VInput.vue'
import { ReclamationExternalFilterList } from '@/features/reclamation-external/filter-list'
import AppPagination from '@/components/ui/AppPagination.vue'
import NavTabsSwiper from '@/shared/ui/tabs/NavTabsSwiper.vue'

import { mixinIsFilter } from '@/mixins/mixinIsFilter'
import { mixinCurrentRouteQueryPage } from '@/mixins/router/mixinCurrentRouteQueryPage'

import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
import { findByKeyValue } from '@/utils/array/find'
import { goToPage } from '@/utils/router'

export default {
  name: 'ReclamationListPage',
  components: {
    AppPagination,
    NavTabsSwiper,
    VInput,
    ButtonRefresh,
    VButton,
    ReclamationExternalCard,
    ReclamationExternalFilterList
  },
  mixins: [
    mixinIsFilter,
    mixinCurrentRouteQueryPage
  ],

  computed: {
    ...mapState('reclamationExternal/filterList', {
      search: 'search',
      reclamations: 'reclamations',
      pagination: 'pagination',
      tabs: 'tabs',
      isLoading: 'isLoading'
    }),
    ...mapGetters('reclamationExternal/filterList', {
      getFilter: 'getFilter'
    }),

    searchValue: {
      get () {
        return this.search
      },
      set (value) {
        this.SET_PAGE(1)
        this.SET_SEARCH(value)
      }
    },

    currentStatusId () {
      const status = findByKeyValue(reclamationExternalModel.STATUSES, 'value', this.$route.params.status)
      return status.id
    }
  },
  watch: {
    '$route.params.status': {
      handler () {
        if (this.isLoading) return
        this.SET_PAGE(1)
        this.fetchReclamations()
      }
    }
  },

  created () {
    if (!this.$route.params.status) {
      goToPage(this.$store.state.pages.reclamationPages.list, {
        status: reclamationExternalModel.STATUSES[0].value
      })
    }
  },

  async mounted () {
    await this.fetchReclamations()
    await this.$store.dispatch('scroll/scrollToNode')
  },

  methods: {
    ...mapMutations('reclamationExternal/filterList', {
      SET_SEARCH: 'SET_SEARCH',
      SET_PAGE: 'SET_PAGE'
    }),

    ...mapActions('reclamationExternal/filterList', {
      fetchReclamations: 'fetchReclamations',
      fetchStatusesCount: 'fetchStatusesCount'
    }),

    setSearchAndFetch (value) {
      this.SET_PAGE(1)
      this.SET_SEARCH(value)
      this.fetchReclamations()
    },

    goToReclamationCreatePage () {
      goToPage(this.$store.state.pages.reclamationPages.create)
    },

    loadPage (num = 1) {
      this.SET_PAGE(num)
      this.fetchReclamations()
    }
  }
}
</script>
